<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">สร้างใบเสร็จ</h4>
      </div>

      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="6">
            <h5>
              <v-icon large>mdi-auto-fix</v-icon>
              หัตถการ/คอร์ส
            </h5>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6">
                <v-select
                  :items="itemsOperativecourse"
                  v-model="OperativecourseData.name"
                  item-text="name"
                  item-value="name"
                  label="หัตถการ/คอร์ส"
                  placeholder="เลือกหัตถการ/คอร์ส"
                  no-data-text="ยังไม่มีข้อมูล"
                  single-line
                  hide-details
                ></v-select>
              </v-col>
              <v-spacer/>
              <div class="d-flex align-center justify-end px-3">
                <v-btn
                  text
                  :loading="loaddataOperativecourse"
                  :disabled="loaddataOperativecourse"
                  color="success"
                  @click="addOperativecourseData"
                >
                  เพิ่ม
                  <v-icon class="ml-3">mdi-plus-circle</v-icon>
                </v-btn>
              </div>
            </v-row>
            <v-data-table
              :headers="headersOperativecourse"
              :items="dataOperativecourse"
              :loading="loaddataOperativecourse"
              loading-text="กำลังโหลด..."
              :options="{
                'itemsPerPage': itemsPerPageOperativecourse
              }"
              class="mt-3"
            >
              <template v-slot:item.operativecourse_code="{ item }">
                <div class="text-start">{{item.operativecourse_code}}</div>
              </template>
              <template v-slot:item.operativecourse_name="{ item }">
                <div class="text-start">{{item.operativecourse_name}}</div>
              </template>
              <template v-slot:item.operativecourse_costprice="{ item }">
                <div class="text-end">{{formatThaiBaht(item.operativecourse_costprice)}}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="item.operativecourse_selection_bool"
                    label=""
                    class="pt-0 mt-0"
                    readonly
                    single-line
                    hide-details
                  ></v-checkbox>
                </div>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initOperativecourseData"
                >
                  รีเซ็ต
                </v-btn>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <h5>
              <v-icon large>mdi-account-circle-outline</v-icon>
              ข้อมูลลูกค้า
            </h5>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="5">
                    <v-select
                      :items="itemsCustomer"
                      v-model="customerData.name"
                      item-text="name"
                      item-value="name"
                      label="ชื่อลูกค้า"
                      placeholder="เลือกลูกค้า"
                      no-data-text="ยังไม่มีข้อมูล"
                      class="pt-0"
                      single-line
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-spacer/>
                  <div class="d-flex align-center justify-end px-3">
                    <v-btn
                      color="success"
                      @click="addCustomerData"
                    >
                      <v-icon class="mr-3">mdi-plus-circle</v-icon>
                      เพิ่มลูกค้า
                    </v-btn>
                  </div>
                  <span class="ml-3 text-sm-body-2 font-weight-light error--text">*ลูกค้าใหม่ กรุณาเพิ่มข้อมูลลูกค้าก่อนทำรายการออกใบเสร็จ</span>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <div class="d-flex flex-column justify-center align-center pt-3">
                  <v-avatar size="128">
                    <img
                      src="https://cdn.vuetifyjs.com/images/john.jpg"
                      alt="John"
                    >
                  </v-avatar>
                  <span class="title-customer-data mt-3">ชื่อ - สกุล</span>
                  <span class="label-customer-data">{{customerData.name || '-'}}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-column">
                    <span class="title-customer-data">เลขบัตรประชาชน</span>
                    <span class="label-customer-data">{{customerData.idcardnumber || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                    <span class="title-customer-data">รหัสสมาชิก</span>
                    <span class="label-customer-data">{{customerData.code || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                    <span class="title-customer-data">กรุ๊ปเลือด</span>
                    <span class="label-customer-data">{{customerData.bloodgroup || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                    <span class="title-customer-data">เพศ</span>
                    <span class="label-customer-data">{{customerData.gender === 'male' ? 'ผู้ชาย' : customerData.gender === 'female' ? 'ผู้หญิง' : 'ไม่ระบุ '}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                    <span class="title-customer-data">วันเกิด</span>
                    <span class="label-customer-data">{{moment(customerData.birthdate).add(543, 'year').format('DD / MM / YYYY') || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                    <span class="title-customer-data">โรคประจำตัว</span>
                    <span class="label-customer-data">{{customerData.congenital || 'ไม่มี'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                    <span class="title-customer-data">เบอร์โทรศัพท์</span>
                    <span class="label-customer-data">{{customerData.phonenumber || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                    <span class="title-customer-data">แพ้ยา</span>
                    <span class="label-customer-data">{{customerData.allergy || 'ไม่มี'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                    <span class="title-customer-data">อื่นๆ</span>
                    <span class="label-customer-data">{{customerData.otherallergy || 'ไม่มี'}}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <h5>
              <v-icon large>mdi-pill</v-icon>
              ยา
            </h5>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6">
                <v-select
                  :items="itemsDrug"
                  v-model="DrugData.name"
                  item-text="name"
                  item-value="name"
                  label="ยา"
                  placeholder="เลือกยา"
                  no-data-text="ยังไม่มีข้อมูล"
                  single-line
                  hide-details
                ></v-select>
              </v-col>
              <v-spacer/>
              <div class="d-flex align-center justify-end px-3">
                <v-btn
                  text
                  :loading="loaddataDrug"
                  :disabled="loaddataDrug"
                  color="success"
                  @click="addDrugData"
                >
                  เพิ่ม
                  <v-icon class="ml-3">mdi-plus-circle</v-icon>
                </v-btn>
              </div>
            </v-row>
            <v-data-table
              :headers="headersDrug"
              :items="dataDrug"
              :loading="loaddataDrug"
              loading-text="กำลังโหลด..."
              :options="{
                'itemsPerPage': itemsPerPageDrug
              }"
              class="mt-3"
            >
              <template v-slot:item.drug_code="{ item }">
                <div class="text-start">{{item.drug_code}}</div>
              </template>
              <template v-slot:item.drug_name="{ item }">
                <div class="text-start">{{item.drug_name}}</div>
              </template>
              <template v-slot:item.drug_costprice="{ item }">
                <div class="text-end">{{formatThaiBaht(item.drug_costprice)}}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="item.drug_selection_bool"
                    label=""
                    class="pt-0 mt-0"
                    readonly
                    single-line
                    hide-details
                  ></v-checkbox>
                </div>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initDrugData"
                >
                  รีเซ็ต
                </v-btn>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <h5>
              <v-icon large>mdi-needle</v-icon>
              อุปกรณ์
            </h5>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6">
                <v-select
                  :items="itemsMedical"
                  v-model="MedicalData.name"
                  item-text="name"
                  item-value="name"
                  label="อุปกรณ์"
                  placeholder="เลือกอุปกรณ์"
                  no-data-text="ยังไม่มีข้อมูล"
                  single-line
                  hide-details
                ></v-select>
              </v-col>
              <v-spacer/>
              <div class="d-flex align-center justify-end px-3">
                <v-btn
                  text
                  :loading="loaddataMedical"
                  :disabled="loaddataMedical"
                  color="success"
                  @click="addMedicalData"
                >
                  เพิ่ม
                  <v-icon class="ml-3">mdi-plus-circle</v-icon>
                </v-btn>
              </div>
            </v-row>
            <v-data-table
              :headers="headersMedical"
              :items="dataMedical"
              :loading="loaddataMedical"
              loading-text="กำลังโหลด..."
              :options="{
                'itemsPerPage': itemsPerPageMedical
              }"
              class="mt-3"
            >
              <template v-slot:item.medical_code="{ item }">
                <div class="text-start">{{item.medical_code}}</div>
              </template>
              <template v-slot:item.medical_name="{ item }">
                <div class="text-start">{{item.medical_name}}</div>
              </template>
              <template v-slot:item.medical_costprice="{ item }">
                <div class="text-end">{{formatThaiBaht(item.medical_costprice)}}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="item.medical_selection_bool"
                    label=""
                    class="pt-0 mt-0"
                    readonly
                    single-line
                    hide-details
                  ></v-checkbox>
                </div>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initMedicalData"
                >
                  รีเซ็ต
                </v-btn>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12">
            <h5>
              <v-icon large>mdi-deskphone</v-icon>
              รายการ
            </h5>
            <v-data-table
              :headers="headersList"
              :items="dataList"
              :loading="loaddataList"
              loading-text="กำลังโหลด..."
              :options="{
                'itemsPerPage': itemsPerPageList
              }"
              class="mt-3"
            >
              <template v-slot:item.list_code="{ item }">
                <div class="text-start">{{item.list_code}}</div>
              </template>
              <template v-slot:item.list_name="{ item }">
                <div class="text-start">{{item.list_name}}</div>
              </template>
              <template v-slot:item.list_costprice="{ item }">
                <div class="text-end">{{formatThaiBaht(item.list_costprice)}}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  @click="deleteListItem(item)"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initListData"
                >
                  รีเซ็ต
                </v-btn>
              </template>
            </v-data-table>
            <v-row class="py-3">
              <v-col cols="12" sm="12" md="12" lg="8">
                <v-select
                  :items="itemsList"
                  v-model="addList.name"
                  item-text="name"
                  item-value="name"
                  label="ชื่อคอร์ส/หัตถการ"
                  placeholder="เลืือกชื่อคอร์ส/หัตถการ"
                  no-data-text="ยังไม่มีข้อมูล"
                  outlined
                  single-line
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2">
                <v-text-field
                  v-model="addList.amount"
                  label=""
                  outlined
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2" class="d-flex justify-center align-center">
                <v-btn
                  icon
                  color="success"
                  @click="addListItem()"
                >
                  <v-icon large>mdi-plus-circle</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data text-decoration-underline">รวม</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${formatThaiBaht(totalPrice)} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">ค่ามัดจำ</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <v-text-field
                  v-model="prepaidPrice"
                  label=""
                  suffix="บาท"
                  outlined
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">ส่วนลด</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <v-text-field
                  v-model="discountPrice"
                  label=""
                  suffix="บาท"
                  outlined
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">มูลค่าหลังหักส่วนลด</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${formatThaiBaht(afterDiscountPrice)} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" class="d-flex flex-row align-center">
                <span class="label-customer-data">ภาษีมูลค่าเพิ่ม</span>
                <v-spacer/>
                <div class="d-flex flex-row align-center mr-3">
                  <v-checkbox
                    v-model="vatPrice.haveVat"
                    @change="vatFunc(0)"
                    label=""
                  ></v-checkbox>
                  <span class="title-customer-data pa-0 ma-0">มี Vat</span>
                </div>
                <div class="d-flex flex-row align-center">
                  <v-checkbox
                    v-model="vatPrice.noVat"
                    @change="vatFunc(1)"
                    label=""
                  ></v-checkbox>
                  <span class="title-customer-data pa-0 ma-0">ไม่มี Vat</span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <v-text-field
                  v-model="vatPrice.price"
                  label=""
                  suffix="บาท"
                  :disabled="vatPrice.noVat"
                  outlined
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data text-decoration-underline">รวมทั้งสิ้น</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${formatThaiBaht(netPrice)} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-textarea
                  outlined
                  class="mt-3"
                  name="input-7-4"
                  label=""
                  placeholder="หมายเหตุ"
                  :value="addList.notation"
                  single-line
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
            <div class="d-flex flex-row align-center justify-end">
              <v-btn
                color="success"
                class="mr-3"
                @click="addListFunc()"
              >
                <v-icon class="mr-3">mdi-content-save</v-icon>
                สร้างใบเสร็จ
              </v-btn>
              <v-btn
                color="error"
                @click="cancelListFunc()"
              >
                <v-icon class="mr-3">mdi-close-circle</v-icon>
                ยกเลิกรายการ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data: () => ({
    customerData: {
      code: 'xxxx123456789',
      belongto: 'เป็นเจ้าของคอร์ส',
      bloodgroup: 'B',
      idcardnumber: 'xxxxxxxxxxxxxxxxxxx',
      name: 'นางสาว xxxxx',
      fullname: 'นางสาว xxxxx xxxxxxxxxx',
      nickname: 'บี',
      birthdate: '1989-05-16',
      age: 0,
      gender: 'female',
      occupation: 'ธุรกิจส่วนตัว',
      phonenumber: '0912345678'
    },
    itemsCustomer: [{
      _id: '000',
      name: 'นางสาว xxxxx'
    }],
    itemsCustomerBelongTo: [{
      _id: '000',
      name: 'เป็นเจ้าของคอร์ส',
      value: 'owner'
    }],
    OperativecourseData: {},
    itemsOperativecourse: [],
    loaddataOperativecourse: false,
    dataOperativecourse: [],
    itemsPerPageOperativecourse: 5,
    itemsDrug: [],
    DrugData: {},
    loaddataDrug: false,
    dataDrug: [{
      _id: '000',
      drug_code: 'M0001',
      drug_name: 'ยาพารา',
      drug_amount: 1,
      drug_costprice: 50,
      drug_selection: 'active',
      drug_selection_bool: true
    }, {
      _id: '001',
      drug_code: 'M0002',
      drug_name: 'ยาฆ่าเชื้อ',
      drug_amount: 1,
      drug_costprice: 100,
      drug_selection: 'inactive',
      drug_selection_bool: false
    }, {
      _id: '002',
      drug_code: 'M0003',
      drug_name: 'ยาลดบวม',
      drug_amount: 1,
      drug_costprice: 50,
      drug_selection: 'active',
      drug_selection_bool: true
    }, {
      _id: '003',
      drug_code: 'M0004',
      drug_name: 'ยาแก้อักเสบ',
      drug_amount: 1,
      drug_costprice: 50,
      drug_selection: 'inactive',
      drug_selection_bool: false
    }, {
      _id: '004',
      drug_code: 'M0005',
      drug_name: 'ยาแก้แพ้',
      drug_amount: 1,
      drug_costprice: 50,
      drug_selection: 'inactive',
      drug_selection_bool: false
    }, {
      _id: '005',
      drug_code: 'M0006',
      drug_name: 'ยาแก้แพ้',
      drug_amount: 1,
      drug_costprice: 50,
      drug_selection: 'inactive',
      drug_selection_bool: false
    }, {
      _id: '005',
      drug_code: 'M0007',
      drug_name: 'ยาแก้แพ้',
      drug_amount: 1,
      drug_costprice: 50,
      drug_selection: 'active',
      drug_selection_bool: true
    }],
    itemsPerPageDrug: 5,
    itemsMedical: [],
    MedicalData: {},
    loaddataMedical: false,
    dataMedical: [{
      _id: '000',
      medical_code: 'E0001',
      medical_name: 'เข็มทู่สำหรับร้อยไหมคอลลาเจน',
      medical_amount: 1,
      medical_costprice: 25,
      medical_selection: 'inactive',
      medical_selection_bool: false
    }, {
      _id: '001',
      medical_code: 'E0002',
      medical_name: 'เข็มทู่ สำหรับร้อยไหมทอร์นาโด',
      medical_amount: 1,
      medical_costprice: 50,
      medical_selection: 'active',
      medical_selection_bool: true
    }, {
      _id: '002',
      medical_code: 'E0003',
      medical_name: 'เข็มทู่สำหรับฉีดฟิลเลอร์ใต้ตา',
      medical_amount: 1,
      medical_costprice: 150,
      medical_selection: 'inactive',
      medical_selection_bool: false
    }, {
      _id: '003',
      medical_code: 'E0004',
      medical_name: 'เข็มทู่ สำหรับร้อยไหมใต้ตา',
      medical_amount: 1,
      medical_costprice: 25,
      medical_selection: 'inactive',
      medical_selection_bool: false
    }, {
      _id: '004',
      medical_code: 'E0005',
      medical_name: 'อุปกรณ์ทำแผล',
      medical_amount: 1,
      medical_costprice: 200,
      medical_selection: 'inactive',
      medical_selection_bool: false
    }, {
      _id: '005',
      medical_code: 'E0006',
      medical_name: 'อุปกรณ์ทำแผล',
      medical_amount: 1,
      medical_costprice: 200,
      medical_selection: 'inactive',
      medical_selection_bool: false
    }, {
      _id: '005',
      medical_code: 'E0007',
      medical_name: 'อุปกรณ์ทำแผล',
      medical_amount: 1,
      medical_costprice: 200,
      medical_selection: 'inactive',
      medical_selection_bool: false
    }],
    itemsPerPageMedical: 5,
    loaddataList: false,
    itemsList: [],
    dataList: [],
    itemsPerPageList: 5,
    addList: {
      assistant_name: '',
      amount: 1
    },
    totalPrice: 0,
    prepaidPrice: 0,
    discountPrice: 0,
    afterDiscountPrice: 0,
    vatPrice: {
      haveVat: false,
      noVat: true,
      price: 0
    },
    netPrice: 0,
    canaddtoeditcourselist: true
  }),
  computed: {
    headersOperativecourse () {
      return [
        {
          text: 'รหัสคอร์ส',
          align: 'center',
          value: 'operativecourse_code',
          sortable: false
        }, {
          text: 'ชื่อคอร์ส/หัตถการ',
          align: 'center',
          value: 'operativecourse_name',
          sortable: false
        }, {
          text: 'ราคา',
          align: 'center',
          value: 'operativecourse_costprice',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'operativecourse_amount',
          sortable: false
        }, {
          text: 'เลือก',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    headersDrug () {
      return [
        {
          text: 'รหัสคอร์ส',
          align: 'center',
          value: 'drug_code',
          sortable: false
        }, {
          text: 'ชื่อยา',
          align: 'center',
          value: 'drug_name',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'drug_amount',
          sortable: false
        }, {
          text: 'ราคา',
          align: 'center',
          value: 'drug_costprice',
          sortable: false
        }, {
          text: 'เลือก',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    headersMedical () {
      return [
        {
          text: 'รหัสคอร์ส',
          align: 'center',
          value: 'medical_code',
          sortable: false
        }, {
          text: 'ชื่ออุปกรณ์',
          align: 'center',
          value: 'medical_name',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'medical_amount',
          sortable: false
        }, {
          text: 'ราคา',
          align: 'center',
          value: 'medical_costprice',
          sortable: false
        }, {
          text: 'เลือก',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    headersList () {
      return [{
        text: '',
        align: 'center',
        value: 'no',
        sortable: false
      }, {
        text: 'รหัสคอร์ส',
        align: 'center',
        value: 'list_code',
        sortable: false
      }, {
        text: 'ชื่อคอร์ส/หัตถการ',
        align: 'center',
        value: 'list_name',
        sortable: false
      }, {
        text: 'จำนวนครั้ง',
        align: 'center',
        value: 'list_amount',
        sortable: false
      }, {
        text: 'ราคา',
        align: 'center',
        value: 'list_costprice',
        sortable: false
      }, {
        text: '',
        align: 'center',
        value: 'actions',
        sortable: false
      }]
    }
  },
  created () {
    const self = this
    self.initOperativecourseData()
    self.initListData()
  },
  watch: {
    prepaidPrice () {
      const self = this
      self.computedNetPrice()
    },
    discountPrice () {
      const self = this
      self.computedNetPrice()
    },
    'vatPrice.price' () {
      const self = this
      self.computedNetPrice()
    }
  },
  methods: {
    formatThaiBaht (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    addOperativecourseData () {
      const self = this
      console.log('add: ', self.OperativecourseData)
    },
    editOperativecourseData () {
      const self = this
      console.log('edit: ', self.OperativecourseData)
    },
    initOperativecourseData () {
      const self = this
      self.loaddataOperativecourse = true
      var temp = []
      temp.push({
        _id: '000',
        operativecourse_code: 'BTS012',
        operativecourse_name: 'Botox กรามไม่จำกัดยูนิต+ย้ำฟรี',
        operativecourse_amount: 1,
        operativecourse_costprice: 2999,
        operativecourse_unit: 'เข็ม',
        operativecourse_selection: 'inactive',
        operativecourse_selection_bool: false
      }, {
        _id: '001',
        operativecourse_code: 'BTM013',
        operativecourse_name: 'Botox กราม+เหนียง+หน้าไม่จำกัดยูนิต+ย้ำฟรี+หน้าใส 2 เข็ม',
        operativecourse_amount: 1,
        operativecourse_costprice: 4999,
        operativecourse_unit: 'เข็ม',
        operativecourse_selection: 'active',
        operativecourse_selection_bool: true
      }, {
        _id: '002',
        operativecourse_code: 'PCL019',
        operativecourse_name: 'ไหมนางฟ้าPCL+PLLA 4 เส้น',
        operativecourse_amount: 1,
        operativecourse_costprice: 7999,
        operativecourse_unit: 'เส้น',
        operativecourse_selection: 'inactive',
        operativecourse_selection_bool: false
      }, {
        _id: '003',
        operativecourse_code: '8D0024',
        operativecourse_name: 'รักษาหลุมสิว',
        operativecourse_amount: 1,
        operativecourse_costprice: 2999,
        operativecourse_unit: 'ครั้ง',
        operativecourse_selection: 'inactive',
        operativecourse_selection_bool: false
      }, {
        _id: '004',
        operativecourse_code: 'TD0013',
        operativecourse_name: 'ไหมทอร์นาโด 4 เส้น',
        operativecourse_amount: 1,
        operativecourse_costprice: 3999,
        operativecourse_unit: 'เส้น',
        operativecourse_selection: 'active',
        operativecourse_selection_bool: true
      }, {
        _id: '005',
        operativecourse_code: 'TD0015',
        operativecourse_name: 'ไหมทอร์นาโด 4 เส้น+ยกกระชับใบหน้า',
        operativecourse_amount: 1,
        operativecourse_costprice: 5999,
        operativecourse_unit: 'เส้น',
        operativecourse_selection: 'inactive',
        operativecourse_selection_bool: false
      })
      setTimeout(() => {
        self.dataOperativecourse = temp
        self.loaddataOperativecourse = false
      }, 300)
    },
    initDrugData () {
      const self = this
      self.loaddataDrug = true
      setTimeout(() => {
        self.loaddataDrug = false
      }, 300)
    },
    addDrugData () {
      const self = this
      console.log('add drug: ', self.DrugData)
    },
    initMedicalData () {
      const self = this
      self.loaddataMedical = true
      setTimeout(() => {
        self.loaddataMedical = false
      }, 300)
    },
    addMedicalData () {
      const self = this
      console.log('add medical: ', self.MedicalData)
    },
    addCustomerData () {
      console.log('add customer')
    },
    initListData () {
      const self = this
      self.loaddataList = true
      var temp = []
      temp.push({
        _id: '000',
        no: 1,
        list_code: 'BTM013',
        list_name: 'Botox กราม+เหนียง+หน้าไม่จำกัดยูนิต+ย้ำฟรี+หน้าใส 2 เข็ม',
        list_amount: 1,
        list_costprice: 4999,
        list_unit: 'เข็ม'
      }, {
        _id: '001',
        no: 2,
        list_code: 'TD0013',
        list_name: 'ไหมทอร์นาโด 4 เส้น',
        list_amount: 1,
        list_costprice: 3999,
        list_unit: 'เส้น'
      }, {
        _id: '002',
        no: 3,
        list_code: 'E002',
        list_name: 'เข็มทู่ สำหรับร้อยไหมทอร์นาโด',
        list_amount: 4,
        list_costprice: 200,
        list_unit: 'เข็ม'
      }, {
        _id: '003',
        no: 4,
        list_code: 'M0001',
        list_name: 'ยาพารา',
        list_amount: 1,
        list_costprice: 50,
        list_unit: 'เม็ด'
      }, {
        _id: '004',
        no: 5,
        list_code: 'M003',
        list_name: 'ยาลดบวม',
        list_amount: 1,
        list_costprice: 50,
        list_unit: 'เม็ด'
      })
      setTimeout(() => {
        self.dataList = temp
        self.totalListItemPrice()
        self.loaddataList = false
      }, 300)
    },
    deleteListItem (item) {
      const self = this
      self.loaddataList = true
      var itemindex = null
      itemindex = self.dataList.findIndex(el => {
        return el._id === item._id
      })
      swal(`ต้องการลบรายการ "${item.list_name} (${item.list_code})" ใช่หรือไม่`, {
        dangerMode: true,
        buttons: {
          cancel: 'ไม่ใช่',
          confirm: {
            text: 'ใช่',
            value: 'confirm'
          }
        }
      }).then((value) => {
        if (value === 'confirm') {
          swal('สำเร็จ', 'ลบข้อมูลสำเร็จ', 'success', {
            button: false,
            timer: 2000
          }).then(() => {
            self.dataList.splice(itemindex, 1)
            self.loaddataList = false
          })
        } else {
          swal('คำเตือน', 'ยังไม่ได้ลบข้อมูล', 'warning', {
            button: false,
            timer: 3000
          })
          self.initListData()
        }
      })
    },
    addListItem () {
      const self = this
      console.log('add list: ', self.addList)
    },
    totalListItemPrice () {
      const self = this
      var sum = 0
      sum = self.dataList.reduce((a, b) => {
        return (a || 0) + (b.list_costprice || 0)
      }, 0)
      self.totalPrice = sum
      self.prepaidPrice = 0
      self.discountPrice = 98
      self.afterDiscountPrice = self.totalPrice - self.prepaidPrice - self.discountPrice
      self.netPrice = self.totalPrice - self.prepaidPrice - self.discountPrice - self.vatPrice.price
    },
    vatFunc (num) {
      const self = this
      if (num === 0) {
        if (self.vatPrice.haveVat) {
          self.vatPrice.noVat = false
        } else if (!self.vatPrice.haveVat) {
          self.vatPrice.noVat = true
        }
      } else if (num === 1) {
        if (self.vatPrice.noVat) {
          self.vatPrice.haveVat = false
        } else if (!self.vatPrice.noVat) {
          self.vatPrice.haveVat = true
        }
      }
    },
    computedNetPrice () {
      const self = this
      self.afterDiscountPrice = self.totalPrice - self.prepaidPrice - self.discountPrice
      self.netPrice = self.totalPrice - self.prepaidPrice - self.discountPrice - self.vatPrice.price
    },
    addListFunc () {
      swal('สำเร็จ', 'สร้างใบเสร็จสำเร็จ', 'success', {
        button: false,
        timer: 2000
      })
    },
    cancelListFunc () {
      swal('สำเร็จ', 'ยกเลิกรายการสำเร็จ', 'success', {
        button: false,
        timer: 2000
      })
    }
  }
}
</script>

<style scoped>
@import "../../styles/DrugsAndMedical_AddStock.css";
@import "../../styles/Service.css";
</style>
